import Vue from 'vue'
import App from './App.vue'
import LongdoMap from 'longdo-map-vue' 

Vue.config.productionTip = false
Vue.use(LongdoMap, {     
  load: {         
       apiKey: '9b8d7c745502db887b835ebe79b8e563'     
  } 
})

new Vue({
  render: h => h(App),
}).$mount('#app')
