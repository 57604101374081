<template>
    <!-- <longdo-map @load="loadMap">
      <longdo-map-marker @add="addMarker" :location="{ lon: loncurrent, lat: latcurrent }" />
    </longdo-map> -->
    <longdo-map @load="loadMap">
      <longdo-map-marker 
      v-if="loncurrent"
      @add="addMarker" 
      :location="{ lon: loncurrent, lat: latcurrent }"
      title="current location"
      detail="detail current location" />
    </longdo-map>
    <!-- <longdo-map>
        <longdo-map-polygon
            :location="locationList"
            :lineWidth="2"
            :lineColor="'rgba(0, 0, 0, 1)'"
            :fillColor="'rgba(255, 0, 0, 0.4)'"
        />
    </longdo-map> -->
    <!-- <longdo-map >         
        <longdo-map-marker             
            v-for="(item, i) in markers"             
            :key="i"             
            :location="item.location"
            :title="item.title"             
            :detail="item.detail"     
        />     
    </longdo-map>  -->
</template>

<script>
// import longdo from "longdo-map-vue";

export default {
  name: "App",
  components: {},
  data() {
    return {
      loncurrent:'',
      locat:{},
      locationList: [
        { lon: 99, lat: 14 },
        { lon: 100, lat: 13 },
        { lon: 102, lat: 13 },
        { lon: 103, lat: 14 },
      ],
      markers: [],
    };
  },
  methods: {
    loadMap(map) {
      // console.log(map);
      this.loncurrent=map.location().lon;
      this.latcurrent=map.location().lat;
    },
    addMarker(marker) {
      console.log(marker.location());
    },
  },
  mounted() {
  },
};
</script>

<style>
html,body{
  height: 100%;
  margin: 0px;
}
</style>
